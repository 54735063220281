import React from 'react'
import * as classes from './about.module.scss'
import { Row, Col, Container } from 'react-bootstrap'

export default function About({ data }) {
  if (data === null) {
    return null
  }

  const firstCol = 1
  const secondCol = 2

  return (
    <section className={classes.wrapper}>
      <Container>
        <h2>{data.title}</h2>
        <Row className={classes.row}>
          <Col md={4} className={classes.col}>
            {data.items
              .map((item) => {
                return (
                  <div key={item.title}>
                    <p className={classes.title}>{item.title}</p>
                    <p className={classes.text}>{item.text}</p>
                  </div>
                )
              })
              .slice(0, firstCol)}
          </Col>
          <Col md={4} className={classes.col}>
            {data.items
              .map((item) => {
                return (
                  <div key={item.title}>
                    <p className={classes.title}>{item.title}</p>
                    <p className={classes.text}>{item.text}</p>
                  </div>
                )
              })
              .slice(firstCol, firstCol + secondCol)}
          </Col>
          <Col md={4} className={classes.col}>
            {data.items
              .map((item) => {
                return (
                  <div key={item.title}>
                    <p className={classes.title}>{item.title}</p>
                    <p className={classes.text}>{item.text}</p>
                  </div>
                )
              })
              .slice(firstCol + secondCol, data.items.lenght)}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
