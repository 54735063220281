import React from 'react'
import * as classes from './second.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import model3d from '../../components/model3d'

export default function Second({ data }) {
  const [outside, setInside] = React.useState(true)

  React.useEffect(() => {
    model3d()
  }, [outside])

  if (data === null) {
    return null
  }
  return (
    <section className={classes.wrapper}>
      <Container className={classes.fly}>
        <h2>{data.title}</h2>
        <button className={outside ? classes.active : ''} onClick={() => setInside(true)}>
          Снаружи
        </button>
        <button className={outside ? '' : classes.active} onClick={() => setInside(false)}>
          Внутри
        </button>
      </Container>
      <Container>
        <div className={classes.image_wrapper}>
          {outside ? (
            <>
              <div className="3d">
                <img src="https://sruboff-company.ru/baniimg/BARN-1001.png" alt="3d-1" />
                <img src="https://sruboff-company.ru/baniimg/BARN-1003.png" alt="3d-2" />
                <img src="https://sruboff-company.ru/baniimg/BARN-1005.png" alt="3d-3" />
                <img src="https://sruboff-company.ru/baniimg/BARN-1007.png" alt="3d-4" />
                <img src="https://sruboff-company.ru/baniimg/BARN-1009.png" alt="3d-5" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10011.png" alt="3d-6" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10013.png" alt="3d-7" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10015.png" alt="3d-8" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10017.png" alt="3d-9" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10019.png" alt="3d-10" />

                <img src="https://sruboff-company.ru/baniimg/BARN-10021.png" alt="3d-11" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10023.png" alt="3d-12" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10025.png" alt="3d-13" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10027.png" alt="3d-14" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10029.png" alt="3d-15" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10031.png" alt="3d-16" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10033.png" alt="3d-17" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10035.png" alt="3d-18" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10037.png" alt="3d-19" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10039.png" alt="3d-20" />

                <img src="https://sruboff-company.ru/baniimg/BARN-10041.png" alt="3d-21" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10043.png" alt="3d-22" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10045.png" alt="3d-23" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10047.png" alt="3d-24" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10049.png" alt="3d-25" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10051.png" alt="3d-26" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10053.png" alt="3d-27" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10055.png" alt="3d-28" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10057.png" alt="3d-29" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10059.png" alt="3d-30" />

                <img src="https://sruboff-company.ru/baniimg/BARN-10061.png" alt="3d-31" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10063.png" alt="3d-32" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10065.png" alt="3d-33" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10067.png" alt="3d-34" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10069.png" alt="3d-35" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10071.png" alt="3d-36" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10073.png" alt="3d-37" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10075.png" alt="3d-38" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10077.png" alt="3d-39" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10079.png" alt="3d-40" />

                <img src="https://sruboff-company.ru/baniimg/BARN-10081.png" alt="3d-41" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10083.png" alt="3d-42" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10085.png" alt="3d-43" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10087.png" alt="3d-44" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10089.png" alt="3d-45" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10091.png" alt="3d-46" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10093.png" alt="3d-47" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10095.png" alt="3d-48" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10096.png" alt="3d-49" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10097.png" alt="3d-50" />
                <img src="https://sruboff-company.ru/baniimg/BARN-10099.png" alt="3d-51" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <StaticImage src="https://i.ibb.co/VYSp64c/mouse.png" alt="mouse" />
              </div>
            </>
          ) : (
            <StaticImage src="https://sruboff-company.ru/banimain/block-7.jpg" alt="Баня внутри" />
          )}
        </div>
        <Row className={classes.row}>
          {data.cols.map((item, i) => {
            return (
              <Col md={3} key={i}>
                {outside
                  ? item.outside.map((out, index) => {
                      return (
                        <div key={index}>
                          <p className={classes.title}>{out.title}</p>
                          <p className={classes.desc}>{out.desc}</p>
                        </div>
                      )
                    })
                  : item.inside.map((ins, index) => {
                      return (
                        <div key={index}>
                          <p className={classes.title}>{ins.title}</p>
                          <p className={classes.desc}>{ins.desc}</p>
                        </div>
                      )
                    })}
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}
