import React, { useState } from 'react'
import * as classes from './modalAkcii.module.scss'
import { Link } from 'gatsby'

export default function ModalAkcii() {
  const [modalAkciiShow, setModalAkciiShow] = useState(false)

  React.useEffect(() => {
    const i_id = setTimeout(() => setModalAkciiShow(true), 2000)
    return () => {
      clearTimeout(i_id)
    }
  }, [])

  React.useEffect(() => {
    const i_id2 = setInterval(() => setModalAkciiShow(true), 80000)
    return () => {
      clearInterval(i_id2)
    }
  }, [])

  const date = new Date()
  date.setDate(date.getDate() + 23)

  return (
    <div
      className={modalAkciiShow ? `${classes.modalAkcii} ${classes.show}` : classes.modalAkcii}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div className={classes.inner}>
        <div
          role="button"
          tabIndex={0}
          className={classes.close}
          onClick={() => setModalAkciiShow(false)}
          onKeyDown={() => setModalAkciiShow(false)}
        />
        <div className={classes.text}>
          Закажи баню до <span>{date.toLocaleString('ru', { day: 'numeric', month: 'long' })}</span>
          <br />и <span>получи бесплатно</span> "стеклянные межкомнатные двери" или "Обливное
          устройство "колобок"" на выбор!
        </div>
        <div>
          <button>
            <Link to="/catalog">Перейти в каталог</Link>
          </button>
        </div>
      </div>
    </div>
  )
}
