import React from 'react'
import * as classes from './nine.module.scss'
import { Row, Col, Container } from 'react-bootstrap'
import Modal from '../../components/modal'
export default function Nine({ data }) {
  if (data === null) {
    return null
  }

  return (
    <>
      <section className={classes.wrapper}>
        <Container>
          <h2>{data.title}</h2>
          <div className={classes.fly}>
            <div className={classes.line}>
              <span />
            </div>
            <Row className={classes.row}>
              <Col md={4}>
                <p className={classes.title}>{data.first.title}</p>
                <div className={classes.blur}>{data.first.text}</div>
              </Col>
              <Col md={4}>
                <p className={classes.title}>{data.second.title}</p>
                <div className={classes.blur}>{data.second.text}</div>
              </Col>
              <Col md={4}>
                <p className={classes.title} dangerouslySetInnerHTML={{ __html: data.third.title }} />
                <div className={classes.blur}>{data.third.text}</div>
              </Col>
            </Row>
          </div>
          <div className={`${classes.form} d-none d-md-block`}>
            <p className={classes.formTitle}>{data.form.title}</p>
            <div className={classes.formText}>
              <p dangerouslySetInnerHTML={{ __html: data.form.text }} />
              <Modal>Получить консультацию</Modal>
            </div>
          </div>
        </Container>
      </section>
      <div className={`${classes.form} d-md-none`}>
        <Container>
          <p className={classes.formTitle}>{data.form.title}</p>
          <div className={classes.formText}>
            <p dangerouslySetInnerHTML={{ __html: data.form.text }} />
            <Modal>Получить консультацию</Modal>
          </div>
        </Container>
      </div>
    </>
  )
}
