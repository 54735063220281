import React from 'react'
import * as classes from './seven.module.scss'
import { Container } from 'react-bootstrap'
import Form from '../../components/Form'
export default function Seven({ data }) {
  if (data === null) {
    return null
  }

  return (
    <section className={classes.wrapper}>
      <div className={classes.bg}>
        <Container>
          <h2>{data.title}</h2>
          <h3>{data.title2}</h3>
          <p>{data.desc}</p>
        </Container>
      </div>
      <Container className="position-relative">
        <div className={classes.card}>
          <Form alt />
        </div>
      </Container>
    </section>
  )
}
