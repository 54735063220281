import React from 'react'
import * as classes from './first.module.scss'
import BackgroundImage from 'gatsby-background-image'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Modal from '../../components/modal'

export default function First({ data }) {
  if (data === null) {
    return null
  }

  const imageData = [
    data.imageMob.childImageSharp.fluid,
    {
      ...data.image.childImageSharp.fluid,
      media: `(min-width: 520px)`
    }
  ]

  //по data.items отличаем главную от услуг
  const usluga = data.items ? true : false
  return (
    <BackgroundImage
      Tag="section"
      className={`${classes.wrapper} ${usluga ? null : classes.indexPage}`}
      fluid={imageData}
    >
      <Container className={`d-flex flex-column`}>
        <h1 className={`order-1`} dangerouslySetInnerHTML={{ __html: data.title }} />
        {usluga && (
          <>
            <div className={`${classes.card} order-2`}>
              <p>{data.description}</p>
              <Row className={`d-none d-md-flex`}>
                <Col md={4}>{data.items.one}</Col>
                <Col md={4}>{data.items.two}</Col>
                <Col md={4}>{data.items.three}</Col>
              </Row>
            </div>
            <Row className={`d-md-none align-items-center order-4`} style={{ marginTop: '23px' }}>
              <Col xs={6}>
                <div className={`mb-3`}>{data.items.one}</div>
                <div>{data.items.three}</div>
              </Col>

              <Col xs={6}>{data.items.two}</Col>
            </Row>
          </>
        )}
        <div
          className={
            usluga
              ? `${classes.btn_group} order-3`
              : `${classes.btn_group} order-3 ${classes.index}`
          }
        >
          {usluga ? (
            <Modal>Заказать сейчас</Modal>
          ) : (
            <Link to={data.btns.primary.link} className={classes.arrow}>
              {data.btns.primary.text}
            </Link>
          )}
          <Link
            to={data.btns.secondary.link}
            className={`${classes.secondary} ${usluga ? null : classes.arrow} ml-0 ml-md-4`}
          >
            {data.btns.secondary.text}
          </Link>
        </div>
      </Container>
    </BackgroundImage>
  )
}
