import React from 'react'
import * as classes from './five.module.scss'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Modal from '../../components/modal'

export default function Five({ data }) {
  if (data === null) {
    return null
  }

  return (
    <section className={classes.wrapper}>
      <Container>
        <h2>{data.title}</h2>
        <Row className={classes.row}>
          {data.items.map((item, index) => {
            return (
              <Col md={4} className={classes.col} key={index}>
                <p className={classes.titleItem}>{item.title}</p>
                <p>{item.content}</p>
                <p className={classes.titleItem}>{item.title2 ? item.title2 : null}</p>
                <p>{item.content2}</p>
              </Col>
            )
          })}
        </Row>
        <div className={classes.info}>
          <StaticImage src="https://i.ibb.co/VSqtVKq/house.png" alt="house" />
          <p>
            Наши бани можно использовать как для частного пользования, так и для коммерческой
            эксплуатации!
          </p>
        </div>
        <div className="text-center">
          <button>
            <Link to="/catalog">Перейти в каталог</Link>
          </button>
        </div>
        <div className="text-center">
          <Modal text>Получить консультацию</Modal>
        </div>
      </Container>
    </section>
  )
}
