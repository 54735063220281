import React from 'react'
import * as classes from './four.module.scss'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick'
import '../../../node_modules/video-react/styles/scss/video-react.scss'
import { Player } from 'video-react'

export default function Four({ data }) {
  //const [town, setTown] = React.useState(0)

  if (data === null) {
    return null
  }

  var settings = {
    dots: true,
    arrows: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  // const showTown = () => {
  //   return (
  //     <>
  //       <Col md={5}>
  //         <Row>
  //           <Col md={12} style={{ marginBottom: '24px' }}>
  //             <GatsbyImage
  //               image={getImage(data.items[town].img1)}
  //               alt={`${data.items[town].name} image${town}1`}
  //             />
  //           </Col>
  //           <Col md={7}>
  //             <GatsbyImage
  //               image={getImage(data.items[town].img2)}
  //               alt={`${data.items[town].name} image${town}2`}
  //             />
  //           </Col>
  //           <Col md={5}>
  //             <GatsbyImage
  //               image={getImage(data.items[town].img3)}
  //               alt={`${data.items[town].name} image${town}3`}
  //             />
  //           </Col>
  //         </Row>
  //       </Col>
  //       <Col md={7}>
  //         <GatsbyImage
  //           image={getImage(data.items[town].img4)}
  //           alt={`${data.items[town].name} image${town}4`}
  //         />
  //       </Col>
  //     </>
  //   )
  // }

  return (
    <section className={classes.wrapper} id="feedback">
      <Container>
        <h2>{data.title}</h2>
        <Slider className={`${classes.videos}`} {...settings}>
          <div className={classes.slide}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback1.mp4" />
          </div>
          <div className={classes.slide}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback2.mp4" />
          </div>
          <div className={classes.slide}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback3.mp4" />
          </div>
          <div className={classes.slide}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback4.mp4" />
          </div>
          <div className={classes.slide}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback5.mp4" />
          </div>
        </Slider>
        {/* <Row className={`${classes.videos} d-none d-md-flex`}>
          <Col md={4}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback1.mp4" />
          </Col>
          <Col md={4}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback2.mp4" />
          </Col>
          <Col md={4}>
            <Player playsInline src="https://sruboff-company.ru/banimain/feedback3.mp4" />
          </Col>
        </Row> */}
        {/* <div className={`${classes.items} d-none d-md-flex`}>
          {data.items.map((item, index) => {
            return (
              <button
                onClick={() => setTown(index)}
                key={index}
                className={town === index ? classes.active : null}
              >
                <GatsbyImage image={getImage(item.logo)} alt={item.name} />
                <p>{item.name}</p>
              </button>
            )
          })}
        </div>
        <Row className={`${classes.row} d-none d-md-flex`}>{showTown()}</Row>*/}
      </Container>
    </section>
  )
}
