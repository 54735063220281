import React from 'react'

import { Container } from 'react-bootstrap'

import { Link } from 'gatsby'
import Modal from '../../components/modal'
import CatalogModule from '../../components/catalog/CatalogModule'
import * as classes from './three.module.scss'
export default function Three({ data }) {
  if (data === null) {
    return null
  }

  return (
    <section className={classes.wrapper}>
      <Container>
        <h2>{data.title}</h2>
        <CatalogModule />
        <div className={classes.btnGroup}>
          <button className={classes.primary}>
            <Link to="/catalog">Перейти в каталог</Link>
          </button>
          <Modal text>Получить консультацию</Modal>
        </div>
      </Container>
    </section>
  )
}
