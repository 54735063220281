import React from 'react'
import * as classes from './accordion.module.scss'
import { Container } from 'react-bootstrap'

export default function Accordion({ data }) {
  const [show, setShow] = React.useState(false)

  if (data === null) {
    return null
  }

  return (
    <section className={classes.wrapper}>
      <Container>
        <h2>{data.title}</h2>
        <div className={classes.card}>
          {data.items
            .map((item, index) => {
              return (
                <div key={index}>
                  {index === 0 ? (
                    <input
                      type="checkbox"
                      defaultChecked
                      className={`d-none`}
                      id={`item${index}`}
                    />
                  ) : (
                    <input type="checkbox" className={`d-none`} id={`item${index}`} />
                  )}

                  <label className={classes.quest} htmlFor={`item${index}`}>
                    {item.quest}
                  </label>
                  <p
                    className={classes.answer}
                    dangerouslySetInnerHTML={{ __html: item.answer && item.answer }}
                  />
                </div>
              )
            })
            .slice(0, show ? data.items.lenght : 3)}
          <hr />

          <button
            className={show ? `${classes.addInfo} ${classes.infoShow}` : classes.addInfo}
            onClick={() => setShow(!show)}
          >
            {show ? 'Скрыть' : 'Показать все'}
          </button>
        </div>
      </Container>
    </section>
  )
}
