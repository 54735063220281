import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import First from '../blocks/1-first/first'
import Second from '../blocks/2-second/second'
import Three from '../blocks/3-three/three'
import Four from '../blocks/4-four/four'
import Five from '../blocks/5-five/five'
import Accordion from '../blocks/6-accordion/accordion'
import Seven from '../blocks/7-seven/seven'
import About from '../blocks/8-about/about'
import Nine from '../blocks/9-nine/nine'
import ModalAkcii from '../components/modalAkcii'

const Home = ({ data }) => {
  return (
    <Layout
      title={data.metadataJson.title}
      keywords={data.metadataJson.keywords}
      desc={data.metadataJson.description}
      navBg={`#333`}
      brcr={data.metadataJson.brcr}
      brcrAlt
    >
      <First data={data.firstJson} />
      <Second data={data.secondJson} />
      <Three data={data.threeJson} />
      <Four data={data.fourJson} />
      <Five data={data.fiveJson} />
      <Accordion data={data.accordionJson} />
      <Seven data={data.sevenJson} />
      <About data={data.aboutJson} />
      <Nine data={data.nineJson} />
      <ModalAkcii />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query ($slug: String) {
    metadataJson(slug: { eq: $slug }) {
      title
      description
      keywords
      brcr
    }

    firstJson(slug: { eq: $slug }) {
      title
      description
      image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageMob {
        childImageSharp {
          fluid(maxWidth: 520, quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      items {
        one
        two
        three
      }
      btns {
        primary {
          text
          link
        }
        secondary {
          text
          link
        }
      }
    }

    secondJson(slug: { eq: $slug }) {
      title
      cols {
        outside {
          title
          desc
        }
        inside {
          title
          desc
        }
      }
    }

    threeJson(slug: { eq: $slug }) {
      title
    }

    fourJson(slug: { eq: $slug }) {
      title
      items {
        name
        logo {
          childImageSharp {
            gatsbyImageData(width: 100, placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        img1 {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        img2 {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        img3 {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        img4 {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
      }
    }

    fiveJson(slug: { eq: $slug }) {
      title
      text
      items {
        title
        content
        title2
        content2
      }
    }

    accordionJson(slug: { eq: $slug }) {
      title
      items {
        quest
        answer
      }
    }

    sevenJson(slug: { eq: $slug }) {
      title
      title2
      desc
    }

    aboutJson(slug: { eq: $slug }) {
      title
      items {
        title
        text
      }
    }

    nineJson(slug: { eq: $slug }) {
      title
      first {
        title
        text
      }
      second {
        title
        text
      }
      third {
        title
        text
      }
      form {
        title
        text
        btn
      }
    }
  }
`
